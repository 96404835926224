<template>
  <div class="loader">
    <img src="./assets/img/loader_mBank_icon.gif" alt="Obrazek informujący o ładowaniu strony" />
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
body #app .loader-wrapper .loader {
  width: unset;
  img {
    max-width: 100%;
  }
}
</style>
